<template>
	<el-dialog class="uploadDailog" :title="dialogObj.title" :visible.sync="visiable" width="50%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="80px">
				<el-form-item label="标题">
					<el-input v-model="formData.title"></el-input>
				</el-form-item>

				<el-form-item label="备注说明">
					<el-input type="textarea" v-model="formData.remarks"></el-input>
				</el-form-item>
				<el-form-item label="上传">
					<sm-upload @upload="Upload" :fileList.sync="fileList"></sm-upload>
				</el-form-item>
			</el-form>
			<div class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">提交</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	import smUpload from '@c/smUpload'
	export default {
		components: {
			smUpload
		},
		mixins: [dialogMixin],
		data() {
			return {
				fileList: [],
				formData: {
					title: "",
					remarks: "",
					attachmentList: []
				},
				loading: false
			};
		},
		props: {},
		watch: {
			isShow(val) {
				if (val) {
					this.open();
					this.init()
				}
			}
		},
		methods: {
			Upload(fileObj) {
				this.loading = true;
				const newData = fileObj.newFile; //  3. 拿到刚刚的数据，并将其传给后台
				this.$upload(newData).then((res) => {
					if (res.code == 1000) {
						this.fileList.push({
							name: fileObj.filename,
							url: res.data
						});
						this.loading = false;
					} else {
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.$message.error(error);
				});
			},
			submit() {
				for (var item of this.fileList) {
					this.formData.attachmentList.push(item.url);
				}
				this.loading = true;
				this.$postData("/shop-api/mall/expert-order/report/save", this.formData).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.$message.success(res.msg);
						this.$parent.list();
						this.close();
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			init() {
				this.fileList = [];
				this.formData.orderId = this.dialogObj.record.id;
			},
			//打开弹窗
			open() {
				this.formData = {
					title: "",
					remarks: "",
					attachmentList: []
				};
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.uploadDailog::v-deep .dlalog-body {
		position: relative;
	}

	.uploadDailog::v-deep .dialog-footer {
		text-align: right
	}
</style>
